import { cn } from '#app/utils/misc'

interface BodyProps extends React.HTMLAttributes<HTMLParagraphElement> {
	size?: 'lg' | 'md' | 'sm' | 'xs' | 'xxs' | ''
}

/**
 * Renders a body text with customizable size.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.size='md'] - The size of the body text. Possible values are 'lg', 'md', 'sm', 'xs', and 'xxs'.
 * @param {string} [props.className] - Additional CSS class names to apply to the body text.
 * @param {ReactNode} props.children - The content of the body text.
 * @returns {JSX.Element} The rendered body text component.
 */

const PlentyBody = ({
	size = 'md',
	className,
	children,
	...rest
}: BodyProps) => {
	switch (size) {
		case 'lg':
			return (
				<p
					className={cn(
						`text-[16px] leading-[16px] tracking-[-0.24px] md:text-[18px] md:leading-[22px] md:tracking-[-0.27px]`,
						className,
					)}
					{...rest}
				>
					{children}
				</p>
			)
		case 'md':
			return (
				<p
					className={cn(
						`text-[14px] leading-[16px] tracking-[-0.21px] md:text-[16px] md:leading-[22px] md:tracking-[-0.24px] `,
						className,
					)}
					{...rest}
				>
					{children}
				</p>
			)
		case 'sm':
			return (
				<p
					className={cn(
						`text-[12px] leading-[16px] tracking-[-0.18px] md:text-[14px] md:leading-[22px] md:tracking-[-0.21px] `,
						className,
					)}
					{...rest}
				>
					{children}
				</p>
			)
		case 'xs':
			return (
				<p
					className={cn(
						`text-[10px] leading-[16px] tracking-[-0.15px] md:text-[12px] md:leading-[14px] md:tracking-[-0.18px]`,
						className,
					)}
					{...rest}
				>
					{children}
				</p>
			)
		case 'xxs':
			return (
				<p
					className={cn(
						`text-[8px] leading-[8px] tracking-[-0.12px]`,
						className,
					)}
					{...rest}
				>
					{children}
				</p>
			)
		default: // default go md
			return (
				<p
					className={cn(
						`text-[14px] leading-[16px] tracking-[-0.21px] md:text-[16px] md:leading-[22px] md:tracking-[-0.24px] `,
						className,
					)}
					{...rest}
				>
					{children}
				</p>
			)
	}
}

export default PlentyBody
